@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dfecf7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 30px;
  padding: 20px;
}

h2 {
  font-size: 25px;
  padding: 20px;
}

h3 {
  font-size: 20px;
  padding: 20px;
}

p, a {
  font-size: 16px;
  padding: 5px;
}

/* p {
  width: 70%;
} */

#header-buttons img {
  width: 50px;
}

/* #otherProjects p {
  width: 70%;
}

#sentinel p {
  width: 80%;
} */